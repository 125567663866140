import React from "react";
import Home from "../component/Home/Home";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import "../styles/global.css";
import citySpecificImage from "../images/cities/ocean_beach.png";

const App = () => {
  const pageData = {
    residentialRealtors: {
      title: "Realtors in Ocean Beach, CA",
      subtitle: "With Your Best Interests At Heart",
      description:
        "This is about more than a deal—it's about your family's happiness and building a great future. So whether you're interested in homes for sale in OceanBeach, are looking to sell your house, or would like to explore property investment options, our experienced residential real estate professionals will connect you with the best opportunities. And we'll do it with heart because we're a family too.",
    },
    findNextHome: {
      card1Title: "Ocean Beach Homes for Sale",
      card2Title: "What Is Your Home Worth?",
    },
    listings: {
      title: "Find Your Place in San Diego",
      description: (
        <p>
          From Allied Gardens to University Heights and every community in between, discover the
          latest San Diego properties here. Our listings are pulled directly from the MLS and
          updated in real-time, giving you immediate access to homes the minute they hit the market.
          Your home search begins here!
        </p>
      ),
    },
    meetFamily: {
      title: "Meet Our Family",
      subtitle:
        "We’re dedicated realtors who help families like yours throughout the homeownership journey—from buying Ocean Beach, CA, real estate to selling to investing. We’re advisors who are in your corner from day one.",
    },
    citySpecific: {
      titlePartA: "We’re Honored to Serve",
      titlePartB: "Ocean Beach",
      p1: "Just north of Point Loma lies Ocean Beach, a vibrant beach town known for its pier, sunbathing, and unique local boutiques. If you’re looking for houses for sale in Ocean Beach, you’ll find more than a home here – you’ll find a warm, welcoming community and endless entertainment opportunities, like the Ocean Beach Farmers Market and nearby Newport Avenue.",
      p2: "The Selby Team is standing by, ready to assist with your realty needs. Interested in getting the best deal for your current house? We’ve got you covered. Wondering what kind of investment opportunities await you? There are so many options! Ready to buy your dream home? It’s time to explore real estate in Ocean Beach, CA!",
      image: citySpecificImage,
    },
    cta: {
      description:
        "Connect with a top-rated team of Ocean Beach realtors who listen, are readily available, and will work tirelessly for you.",
    },
  };
  return (
    <LayoutWrapper
      title="Ocean Beach Homes for Sale | Real Estate | Selby Realtors"
      desc="Searching for homes for sale in Ocean Beach? The Selby Team has the newest listings for this beautiful San Diego-area community."
      headerLogo="../../images/Selby_logo.svg"
    >
      <Home pageData={pageData} />
    </LayoutWrapper>
  );
};

export default App;
